import { createStore } from 'vuex'
import axios from 'axios'

import customization from './modules/customization';


var punycode = require('punycode');
import i18n from '../i18n'


function demoAccess(){
	const varname = "$VUE_APP_DEMO_ACCESS";
	return !!process.env[varname.substr(1)] ? process.env[varname.substr(1)] : null
};
function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

const NS_DELAY_MILLISECONDS = 5000;

const errorStatusCode = {
	400: i18n.global.t('It was not possible to process your request'),
	404: i18n.global.t('Your request has semantic errors'),
	409: i18n.global.t('Your request has semantic errors'),
	422: i18n.global.t('Your request has semantic errors'),
	500: i18n.global.t('It was not possible to process your request'),
	501: i18n.global.t('It was not possible to process your request'),
	502: i18n.global.t('It was not possible to process your request'),
}

export const store = createStore({
	state: {
		language: 'en',
    pageType: '',
		dummy: false,
		mobile: false,
		loadingHigh: 0,
		loadingLow: 0,
		lastError: null,
		success: null,
	},
	mutations: {
		reset(state, data){

		},
		startLoading(state, isHigh){
			if (isHigh)
				state.loadingHigh++
			else
				state.loadingLow++
			},
		finishLoading(state, isHigh){
			if (isHigh)
				state.loadingHigh--
			else
				state.loadingLow--
		},
		setError(state, error){
			try{
				console.log(error);
				if (!error.response || !error.response.status) {
					if (!error.status || !errorStatusCode[error.status]) {
						if (error !== false) {
							state.lastError = error
						}
					} else {
						state.lastError = errorStatusCode[error.status]
					}
				}
				else if ( ![401,403].includes(error.response.status)){
					if (!!errorStatusCode[error.response.status]) {
						state.lastError = errorStatusCode[error.response.status]
					} else
					if (error.response.data){
						if (error.response.data.error)
							state.lastError = error.response.data.error
						else if (error.response.statusText)
							state.lastError = error.response.statusText
						else
						state.lastError = error.response.data
					}
					else
						state.lastError = error
				}
				else
					state.lastError = null
			} catch(err){
				state.lastError = error
			}
		},
		setErrorAll(state, error){
			console.log(error);
			try{
				if (error.response.data && error.response.data.error)
					state.lastError = error.response.data.error
				else
					state.lastError = error
			}
			catch(err){
				state.lastError = error
			}
		},
		removeError(state){
			state.lastError = null
		},
		setSuccess(state, success){
			state.success = success;
		},
		removeSuccess(state){
			state.success = null;
		},
		setDummy(state, isDummy){
			state.dummy = isDummy;
		},

		setLanguage(state, lang){
			state.language = lang
		},
    setPageType(state, type) {
      state.pageType = type;
    }
	},
	actions: {
		setSuccess({commit, dispatch}, msg){
			commit('setSuccess', msg);
		},
		setDummy({commit, dispatch}, isDummy){
			commit('setDummy', isDummy);
		},
		setLanguage({commit, dispatch}, lang){
			// enable lazy loading of locales
			i18n.loadLanguageAsync(lang).then(() => commit('setLanguage', lang))
			//i18n.setI18nLanguage(lang)
			//commit('setLanguage', lang)
		},
		bypassBlock ({commit, dispatch}, srcdata) {
			// console.log(srcdata);
			// temp hack to allow login on GUI
			axios.post('/account/checkpasswordoverride', srcdata, {headers: {'x-wait-on-loading': 0}})
			.then(res => {
				let data = res.data;
				if (typeof res.data === 'string')
					data = JSON.parse(res.data);
				window.open(data.url, "_self");
			})
			.catch(error => {
				console.log(error);
				if ( [401,403].includes(error.response.status))
					commit('setErrorAll',  i18n.global.t('Incorrect password'));
				else
					commit('setError', error);
			})
		},
    async sslUnblock ({commit, dispatch}, data) {
      try {
        const res = await axios.post('/subsystems/ns/unblock', data);
        if (res && res.data && res.data.url) {
          setTimeout(() => {
            window.open(res.data.url, "_self");
          }, NS_DELAY_MILLISECONDS);
        }
      } catch (err) {
        if (err.response.data && err.response.data.detail) {
          commit('setErrorAll', err.response.data.detail);
        }
      }
    },
    setPageType({commit, dispatch}, type) {
      commit('setPageType', type);
    }
  },
	getters: {
		loading(state){
			return state.loadingHigh > 0 || state.loadingLow > 0
		},
		isDummy(state){
			return state.dummy;
		},
		error(state){
			return state.lastError;
		},
		success(state){
			return state.success;
		},
		getLanguage(state){
			return state.language
		},
    getLanguages(state) {
      return [
        { id: 'en', name: 'English'},
        { id: 'es', name: 'Español'},
        { id: 'fr', name: 'français'},
        { id: 'de', name: 'Deutsch'},
        { id: 'ru', name: 'русский'},
        { id: 'uk', name: 'українську'},
        { id: 'pt', name: 'Português'},
        { id: 'ar', name: 'عربي'},
        { id: 'he', name: 'עברית'}
      ]
    },
    isTextRtl(state) {
      const rtlLanguages = ['ar', 'he'];
      return rtlLanguages.includes(state.language);
    },
    pageType(state) {
      return state.pageType;
    }
	},
	modules: {
    customization
	}
});


