import { createApp, h } from "vue";
import { createRouter, createWebHistory } from 'vue-router';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import en from 'element-plus/dist/locale/en.mjs'

// Plugins
import App from './App.vue';

// router setup
import routes from './routes/routes';

// Vuex
import { store } from './store/store';
import axios from 'axios';

var VueScrollTo = require('vue-scrollto');
// Vue.use(VueScrollTo)

import i18n from './i18n'

//global mixin, helper functions
import helper from './helpers';

// configure router
export const router = createRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  history: createWebHistory()
});


const app = createApp({
  render: () => h(App)
});

// Initiate other plugins here
app.use(router);
app.use(store);
app.use(i18n)
app.use(VueScrollTo);
app.use(ElementPlus, {
  locale: en,
});

app.mixin(helper);

// error handling: general
app.config.errorHandler = err => {
  console.error('Exception: ' , err);
}

// error handling: general
// Vue.config.errorHandler = err => {
//   console.error('Exception: ' , err);
// }

export const changeRoutes = (to, sso,  next, store) => {
  if (store.getters.isSSO) {
     const redirectSSO = store.getters.getRedirectSSO(sso)
     if (sso === 'logout') {
      store.commit('setSSO', false)
    }
     console.log("changeRoutes -> location", redirectSSO)
     redirectSSO && location.replace(redirectSSO)
  }
    if (to === '')
      next()
    else {
      if (next) {
        const redirectSSO = store.getters.getRedirectSSO(sso)
        if (sso === '' || (sso !== '' && redirectSSO == undefined))
          next({name: to})
      } else {
        router.push({ name: to, query: { out: 'true'}})
      }
    }
}

// error handling: XMLHTTPRequests
axios.interceptors.response.use(
  response => {
    let isHigh = parseInt(response.config.headers['x-wait-on-loading']) === 0
    store.commit('finishLoading', isHigh)
    return response
  },
  error => {
    let isHigh = parseInt(error.config.headers['x-wait-on-loading']) === 0
    store.commit('finishLoading', isHigh)
    try{
        store.commit('setError', error);
    }
    catch(err){
      store.commit('setError', error);
    }
    return Promise.reject(error);
  }
);

//routes guard
router.beforeEach ((to, from, next) => {
  const storeCatch = store;
  if (to.path.startsWith('/')){
    next()
  }
})


// global config axios
const backend_version = "v1";

if(window.hasOwnProperty("cordova")){
  console.log("You're on a mobile device");
  let axiosconf = axios.create({
    baseURL: "http://wsfe-allotsecure.allotdemo.tk/",
  })

  store.state.backend_ok = false;
  axiosconf.get("config/prod")
    .then(res => {
      console.log(res)
      axios.defaults.baseURL = res.data.internalHostRequest.replace(/\/$/,'') + '/api-account/' + backend_version;
      console.log(`baseURL ${axios.defaults.baseURL}`)
      store.state.backend_ok = true;
    })
    .catch(error => {
      console.log(`error ${error}`);
      store.state.backend_ok = false;
    })
}
else {
  axios.defaults.baseURL = '/api-account/' + backend_version;
  store.state.backend_ok = true;
}

axios.defaults.headers.get['Accepts'] = 'application/json';

// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import './polyfills';
const availableLanguages = ["en-US", "es-ES", "ru-RU", "uk-UA", "de-DE", "fr-FR", "pt-BR", "pt-PT", "ar-OM", "he-IL", "ja-JP", "id-ID"]
if( !navigator.language || navigator.language === undefined ) {
  store.dispatch('setLanguage', "en-US");
} else {
  let lang = navigator.language;
  if (lang.includes('ja')) lang = "ja-JP";
  else if (lang.includes('ar')) lang = "ar-OM";
  else if (lang.includes('de')) lang = "de-DE";
  else if (lang.includes('en')) lang = "en-US";
  else if (lang.includes('es')) lang = "es-ES";
  else if (lang.includes('fr')) lang = "fr-FR";
  else if (lang.includes('he')) lang = "he-IL";
  else if (lang.includes('id')) lang = "id-ID";
  else if (lang.includes('pt-BR')) lang = "pt-BR";
  else if (lang.includes('pt-PT')) lang = "pt-PT";
  else if (lang.includes('pt')) lang = "pt-PT";
  else if (lang.includes('ru')) lang = "ru-RU";
  else if (lang.includes('uk')) lang = "uk-UA";
  if( availableLanguages.indexOf(lang) < 0 ) lang = "en-US";
  store.dispatch('setLanguage', lang);
}

// asset imports
import './assets/sass/allot-secure.scss';
import './assets/sass/now-ui-dashboard.scss';
import './assets/css/variables.css';
//import './assets/css/demo.css';

// library auto imports
import 'es6-promise/auto';

import fgInput from './components/Inputs/formGroupInput.vue';
import Card from './components/Cards/Card.vue';
import Button from './components/Button.vue';
import clickOutside from './directives/click-ouside.js';

app.component('fg-input', fgInput);
app.component('card', Card);
app.component('n-button', Button);
app.directive('click-outside', clickOutside);

app.mount("#app");
