export default {
  "stop-page": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية المحتوى"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحماية من التهديدات"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محظور!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حظر طلبك لضمان حمايتك."])},
    "description cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الموقع غير مسموح به من خلال سياسات التصفية المخصصة لك"])},
    "description tp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الموقع غير مسموح به لأنه يعتبر ضارًا"])},
    "description ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الويب الذي تحاول الوصول إليه به شهادة غير صالحة وقد تم حظره."])},
    "Virus infected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصاب بالفيروس"])},
    "Botnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوت نت"])},
    "Spyware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج التجسس"])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب المنع"])},
    "Malware/Phishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرامج الضارة / التصيد"])},
    "ssl_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل الاتصال بالمصدر .الشهادة غير موثوقة."])}
  },
  "stop-po": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية المحتوى"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحماية من التهديدات"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محظور!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حظر طلبك لضمان حمايتك.\nأدخل كلمة المرور الخاصة بك لإلغاء حظره."])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب المنع"])},
    "unblock_card_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع الحظر"])},
    "Unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع الحظر"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمه السر"])},
    "The password field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقل كلمة المرور مطلوب"])}
  },
  "ask-user-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محظور!"])},
    "block message ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الويب الذي تحاول الوصول إليه به شهادة غير صالحة وقد تم حظره."])},
    "ssl_unblock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت لا تزال تثق في الشهادة وتريد الوصول إلى موقع الويب ،فاضغط على زر إلغاء الحظر."])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع الحظر"])}
  }
}