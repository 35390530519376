import { createI18n } from 'vue-i18n'
import elementEsLocale from 'element-plus/dist/locale/es.mjs'
import elementEnLocale from 'element-plus/dist/locale/en.mjs'
import _ from 'lodash'

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: loadLocaleMessages(), // set locale messages
  // messages: {}
})

const allowedTranslations = ['en', 'es'];
const loadedLanguages = [] // our default language that is preloaded

import axios from 'axios';

function setI18nLanguage (lang) {
  i18n.global.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

function loadLanguageAsync(lang) {
  const langToLoad = allowedTranslations.includes(lang) ? lang : 'en';
  // If the language was already loaded
  if (loadedLanguages.includes(langToLoad)) {
    return Promise.resolve(setI18nLanguage(langToLoad))
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-request" */ '@/locales/' + langToLoad + '.json').then(
    messages => {
      langToLoad == 'es' ? _.assign(messages.default, elementEsLocale) : _.assign(messages.default, elementEnLocale);
      i18n.global.setLocaleMessage(langToLoad, messages.default)
      loadedLanguages.push(langToLoad)
      return setI18nLanguage(langToLoad)
    }
  )
  .catch( err => {
    console.log('Error loading locale (' + langToLoad + '): ' + err)
  })
}

i18n['loadLanguageAsync'] = loadLanguageAsync
i18n['setI18nLanguage'] = setI18nLanguage

export default i18n;
