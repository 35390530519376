import axios from "axios";
import _ from 'lodash';

export default {
  getCustomization({commit, state, dispatch, getters, rootGetters}) {
    if (rootGetters.isDummy){
      return new Promise((resolve, reject) => {
        const defaultCustomization = {
          web: "http://www.allot.com",
          companyName: 'Allot',
          appName: 'SecureManagement',
          footerLogin: true,
          env_options: {
            colors: {
              primary: '',
              secondary: '',
            },
            blocking_pages: null
          }
        }
        commit('addCustomization', defaultCustomization)
        resolve(defaultCustomization)
      });
    }
    else{
      return new Promise((resolve, reject) => {
        axios.get('/customization')
          .then(res => {
            if ( res && res.data){
              const customizationData = {
                web: !!res.data.company_url ? res.data.company_url : "http://www.allot.com",
                companyName: !!res.data.company_name ? res.data.company_name : 'Allot',
                appName: !!res.data.app_name ? res.data.app_name : 'SecureManagement',
                footerLogin: !!res.data.show_footer_text,
                env_options: {
                  colors: {
                    primary: _.get(res.data, 'env_options.colors.primary', ''),
                    secondary: _.get(res.data, 'env_options.colors.secondary', ''),
                  },
                  blocking_pages: _.get(res.data, 'env_options.blocking_pages', null)
                }
              }
              commit('addCustomization', customizationData)
              resolve(customizationData)
            }
            else{
              console.log('Customization retrieve error, using defaults')
              resolve(state.customization)
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            resolve(state.customization)
          })
      })
    }
  }
}

