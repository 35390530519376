export default {
  "stop-page": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Filter"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threat Protection"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been blocked to ensure your protection."])},
    "description cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is not allowed by the filtering policies assigned to you."])},
    "description tp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is not allowed because it is deemed to be malicious."])},
    "description ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website you are trying to access has an invalid certificate and it has been blocked."])},
    "Virus infected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virus infected"])},
    "Botnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botnet"])},
    "Spyware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spyware"])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocking reason"])},
    "Malware/Phishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malware/Phishing"])},
    "ssl_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source connection failed. The certificate is not trusted."])}
  },
  "stop-po": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Filter"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threat Protection"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been blocked to ensure your protection.\nEnter your password to unblock it."])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocking reason"])},
    "unblock_card_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock URL"])},
    "Unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "The password field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password field is required"])}
  },
  "ask-user-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked!"])},
    "block message ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website you are trying to access has an invalid certificate and it has been blocked."])},
    "ssl_unblock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you still trust in the certificate and want to access the website press the Unblock button."])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock"])}
  }
}