export default {
  "stop-page": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro de conteúdo"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteção contra ameaças"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua solicitação foi bloqueada para garantir sua proteção."])},
    "description cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este website não é permitido pelas políticas de filtragem que lhe são atribuídas."])},
    "description tp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este website não é permitido porque é considerado malicioso."])},
    "description ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O site que você está tentando acessar tem um certificado inválido e foi bloqueado."])},
    "Virus infected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infectado por vírus"])},
    "Botnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botnet"])},
    "Spyware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spyware"])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo do bloqueio"])},
    "Malware/Phishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malware/Phishing"])},
    "ssl_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falha na conexão de origem. O certificado não é confiável."])}
  },
  "stop-po": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro de conteúdo"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteção contra ameaças"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua solicitação foi bloqueada para garantir sua proteção.\nDigite sua senha para desbloqueá-la."])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo do bloqueio"])},
    "unblock_card_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear o URL"])},
    "Unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra-passe"])},
    "The password field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O campo de senha é obrigatório"])}
  },
  "ask-user-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado!"])},
    "block message ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O site que você está tentando acessar tem um certificado inválido e foi bloqueado."])},
    "ssl_unblock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você ainda confia no certificado e deseja acessar o site pressione o botão Desbloquear."])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear"])}
  }
}