import _ from 'lodash';

export default {
  companyName(state){
    return state.customization.companyName;
  },
  appName(state){
    return state.customization.appName;
  },
  footerLogin(state){
    return state.customization.footerLogin;
  },
  web(state){
    return state.customization.web;
  },
  primaryColor(state) {
    return state.customization.env_options.colors.primary;
  },
  secondaryColor(state) {
    return state.customization.env_options.colors.secondary;
  },
  blockingPageTranslation (state, getters, rootState, rootGetters) {
    const allTranslations = state.customization.env_options.blocking_pages;
    const pageType = rootGetters.pageType;
    const currentLanguage = rootGetters.getLanguage;
    if (allTranslations) {
      const languageCode = Object.keys(allTranslations).find(key => key.toLowerCase().includes(currentLanguage.toLowerCase()));
      return _.get(allTranslations, [languageCode, pageType], null);
    }
    return null;
  }
}

