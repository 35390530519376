export default {
  "stop-page": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтр вмісту"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Захист від загроз"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблоковано!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш запит заблоковано, щоб забезпечити ваш захист."])},
    "description cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Даний сайт заблокований призначеними Вами політиками фільтрації."])},
    "description tp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Даний сайт заблокований, оскільки вважається шкідливим."])},
    "description ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веб-сайт, до якого ви намагаєтесь отримати доступ, має недійсний сертифікат і його заблоковано."])},
    "Virus infected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заражений вірусом"])},
    "Botnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botnet"])},
    "Malware/Phishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шкідливе програмне забезпечення/Фішинг"])},
    "Spyware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spyware"])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина блокування"])},
    "ssl_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося підключитися до джерела. Сертифікат не є дійсним."])}
  },
  "stop-po": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтр вмісту"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Захист від загроз"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблоковано!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш запит заблоковано, щоб забезпечити ваш захист.\nВведіть пароль для розблокування."])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина блокування"])},
    "unblock_card_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розблокувати URL"])},
    "Unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розблокувати"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "The password field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле пароля обов'язкове"])}
  },
  "ask-user-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблоковано!"])},
    "block message ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веб-сайт, до якого ви намагаєтесь отримати доступ, має недійсний сертифікат і його заблоковано."])},
    "ssl_unblock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви довіряєте сертифікату і хочете отримати доступ до веб-сайту, натисніть кнопку Розблокувати."])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розблокувати"])}
  }
}