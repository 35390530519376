<template>
  <component :is="tag"
             :type="nativeType"
             class="btn"
             :disabled="disabled"
             :class="classes"
             :style="themedButtonStyle"
  >
    <span class="btn-label" v-if="$slots.label">
      <slot name="label"></slot>
    </span>
    <slot></slot>
    <span class="btn-label btn-label-right" v-if="$slots.labelRight">
      <slot name="labelRight"></slot>
    </span>
  </component>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'n-button',
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    type: {
      type: String,
      default: 'default'
    },
    nativeType: {
      type: String,
      default: 'button'
    },
    disabled :{
      type: Boolean,
      default: false
    },
    round: Boolean,
    simple: Boolean,
    block: Boolean,
    social: Boolean,
    link: Boolean,
    icon: Boolean,
    wide: Boolean,
    size: String
  },
  computed: {
    ...mapGetters('customization', ['primaryColor']),
    themedButtonStyle() {
      if (this.primaryColor.length) {
        return { '--theme-button-color': this.primaryColor }
      }
    },
    classes() {
      let btnClasses = [
        { 'btn-simple': this.simple },
        { 'btn-icon': this.icon },
        { 'btn-round': this.round },
        { 'btn-block': this.block },
        { 'btn-social': this.social },
        { 'btn-link': this.link },
        { 'btn-wd': this.wide },
        `btn-${this.type}`
      ];
      if (this.size) {
        btnClasses.push(`btn-${this.size}`);
      }
      if (this.primaryColor.length) {
        btnClasses.push(`btn-themed-${this.type}`);
      }
      return btnClasses;
    }
  }
};
</script>
<style lang="scss" scoped>
  .btn-themed-primary {
    background-color: var(--theme-button-color);
    transition: all 0.2s ease;
    &:hover {
      background-color: var(--theme-button-color);
      filter: brightness(var(--theme-button-hover-brightness));
    }
  }
</style>
