<template>
  <div>
    <!--<navbar :show-navbar="showMenu">
      <div class="navbar-wrapper">
        <div class="navbar-toggle" :class="{toggled: showMenu}">
          <navbar-toggle-button @click.native="toggleNavbar">
          </navbar-toggle-button>
        </div>
        <div class="navbar-brand">{{appName}}</div>
      </div>
    </navbar>-->


    <div class="wrapper wrapper-full-page">
      <img alt="background-image" src="/img/bg.jpg" class="background-image"/>
      <div class="full-page section-image" filter-color="black">
        <div class="content centered">
          <div class="app-name-container">{{appName}}</div>
          <div class="container">
            <!-- <zoom-center-transition :duration="pageTransitionDuration" mode="out-in"> -->
              <!-- <router-view></router-view> -->
              <router-view v-slot="{ Component }">
              <Transition :duration="pageTransitionDuration" mode="out-in">
                <component :is="Component" />
              </Transition>
            </router-view>
            <!-- </zoom-center-transition> -->
          </div>
        </div>
        <footer v-if="footerLogin" class="footer">
          <div class="container-fluid">
            <div v-if="companyName" class="copyright">
              &copy; {{year}}, Designed by
              <a :href="web"
                :style="themedLinkStyle">
                {{companyName}}</a>. All rights Reserved.
            </div>
          </div>
        </footer>
        <div
          class="full-page-background"
          style="backgroundImage: url('img/bg.jpg')"
        />
      </div>
    </div>
  </div>
</template>
<script>
//import { Navbar, NavbarToggleButton } from 'src/components';
import {mapGetters} from "vuex";

export default {
  name: 'auth-layout',
  components: {
    //Navbar,
    //NavbarToggleButton,
    // ZoomCenterTransition
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page'
    };
  },
  computed: {
    ...mapGetters('customization', [
      'appName',
      'companyName',
      'web',
      'footerLogin',
      'primaryColor'
    ]),
    themedLinkStyle() {
      if (this.primaryColor) {
        return { color: this.primaryColor }
      }
    },
    languages(){
      return this.$store.getters.getLanguages
    },
  },
  methods: {
    setLanguage(language){
      this.$store.dispatch('setLanguage', language)
    },
    /*toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },*/
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  },
  created () {
    this.$store.dispatch("customization/getCustomization");
    const availableLanguages = ["en", "es", "ru", "uk", "de", "fr", "pt", "ar", "he"]
    if( !navigator.language || navigator.language === undefined )
      this.setLanguage("en")
    else{
      let lang = navigator.language.split(/-|_/)[0]
      if( availableLanguages.indexOf(lang) < 0 ) lang = "en"
      this.setLanguage(lang)
    }
  },
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
.reason-container {
  margin: 46px 0px 20px;
}

.background-image {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}
</style>
