export default {
  "stop-page": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhaltsfilter"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrohungsschutz"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesperrt!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage wurde zu Ihrem Schutz blockiert."])},
    "description cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Website ist gemäß den Ihnen zugewiesenen Filtereinstellungen nicht zulässig."])},
    "description tp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Website ist nicht erlaubt, da sie als gefährlich eingestuft wurde."])},
    "description ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Website, auf die Sie zugreifen möchten, hat ein ungültiges Zertifikat und wurde gesperrt."])},
    "Virus infected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virus infiziert"])},
    "Botnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botnet"])},
    "Spyware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spyware"])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sperrgrund"])},
    "Malware/Phishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malware/Phishing"])},
    "ssl_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quellverbindung fehlgeschlagen. Das Zertifikat ist nicht vertrauenswürdig."])}
  },
  "stop-po": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhaltsfilter"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrohungsschutz"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesperrt!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage wurde zu Ihrem Schutz gesperrt.\nGeben Sie Ihr Passwort ein, um es zu entsperren."])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sperrgrund"])},
    "unblock_card_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL entsperren"])},
    "Unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entsperren"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "The password field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwortfeld ist erforderlich"])}
  },
  "ask-user-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesperrt!"])},
    "block message ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Website, auf die Sie zugreifen möchten, hat ein ungültiges Zertifikat und wurde gesperrt."])},
    "ssl_unblock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie dem Zertifikat immer noch vertrauen und auf die Website zugreifen möchten, klicken Sie auf den Entsperr-Knopf."])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entsperren"])}
  }
}