export default {
  "stop-page": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סינון תכנים"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגנה נגד איומים"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חסום!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בקשתך נחסמה כדי להבטיח את הגנתך."])},
    "description cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אתר זה חסום על פי מדיניות הסינון שהוקצתה לך."])},
    "description tp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אתר זה חסום מכיוון שהוא נחשב זדוני."])},
    "description ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לאתר שאליו אתה מנסה לגשת אין תעודת הצפנה בתוקף והוא נחסם."])},
    "Virus infected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נגוע בווירוס"])},
    "Botnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בוטנט"])},
    "Spyware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רוגלה"])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיבת חסימה"])},
    "Malware/Phishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תוכנה זדונית/פישינג"])},
    "ssl_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חיבור מאובטח נכשל. פרוטוקול ההצפנה באתר אינו מהימן."])}
  },
  "stop-po": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סינון תכנים"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגנה נגד איומים"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חסום!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בקשתך נחסמה כדי להבטיח את הגנתך. \n הזן סיסמה כדי לבטל את החסימה."])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיבת חסימה"])},
    "unblock_card_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ביטול חסימה"])},
    "Unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בטל חסימה"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה"])},
    "The password field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נא להזין סיסמה"])}
  },
  "ask-user-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חסום!"])},
    "block message ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לאתר שאליו אתה מנסה לגשת אין תעודת הצפנה בתוקף והוא נחסם."])},
    "ssl_unblock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אם אתה סומך על האתר וברצונך לגשת אליו לחץ על בטל חסימה."])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בטל חסימה"])}
  }
}