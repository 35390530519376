export default {
  "stop-page": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrado de Contenidos"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protección frente a amenazas"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su petición ha sido bloqueada para garantizar su protección."])},
    "description cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio está bloqueado según las políticas de filtrado que tienes asignadas."])},
    "description tp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio está bloqueado por ser considerado como malicioso."])},
    "description ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El sitio web al que estabas intentando acceder tiene un certitificado inválido y ha sido bloqueado."])},
    "Virus infected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infectado con virus"])},
    "Botnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botnet"])},
    "Spyware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software espía"])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo"])},
    "Malware/Phishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malware/Phishing"])},
    "ssl_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de conexión. El certificado no es confiable."])}
  },
  "stop-po": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrado de Contenidos"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protección frente a amenazas"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su petición ha sido bloqueada para garantizar su protección.\nIntroduzca su contraseña para desbloquear."])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo"])},
    "unblock_card_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear la URL"])},
    "Unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "The password field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de contraseña es requerido"])}
  },
  "ask-user-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado!"])},
    "block message ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El sitio web al que estabas intentando acceder tiene un certitificado inválido y ha sido bloqueado."])},
    "ssl_unblock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si confías en el certificado y quieres acceder a este sitio web a pesar de ello, presiona el botón Desbloquear."])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear"])}
  }
}