export default {
  "stop-page": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre de contenu"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection contre les menaces"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqué!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été bloquée pour assurer votre protection."])},
    "description cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce site Web n'est pas autorisé par les politiques de filtrage qui vous sont attribuées."])},
    "description tp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce site n'est pas autorisé car il est considéré comme malveillant."])},
    "description ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le site Web auquel vous essayez d'accéder a un certificat invalide et il a été bloqué."])},
    "Virus infected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virus infecté"])},
    "Botnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botnet"])},
    "Spyware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spyware"])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison du blocage"])},
    "Malware/Phishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malware/Phishing"])},
    "ssl_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la connexion. Le certificat n'est pas de confiance."])}
  },
  "stop-po": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre de contenu"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection contre les menaces"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqué!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été bloquée pour assurer votre protection.\nEntrez votre mot de passe pour le débloquer."])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison du blocage"])},
    "unblock_card_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débloquer l'URL"])},
    "Unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débloquer"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "The password field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ mot de passe est obligatoire"])}
  },
  "ask-user-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqué!"])},
    "block message ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le site Web auquel vous essayez d'accéder a un certificat invalide et il a été bloqué."])},
    "ssl_unblock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous faites toujours confiance au certificat et que vous souhaitez accéder au site Web, appuyez sur le bouton Débloquer."])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débloquer"])}
  }
}