export default () => ({
  customization: {
    web: '',
    companyName: '',
    appName: '',
    footerLogin: true,
    env_options: {
      colors: {
        primary: '',
        secondary: '',
      },
      blocking_pages: null
    }
  }
})
