export default {
  "stop-page": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр содержимого"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Защита от угроз"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировано!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш запрос был заблокирован для обеспечения вашей защиты."])},
    "description cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данный сайт заблокирован назначенными Вами политиками фильтрации."])},
    "description tp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данный сайт заблокирован, поскольку считается вредоносным."])},
    "description ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веб-сайт, к которому вы пытаетесь получить доступ, имеет недействительный сертификат и заблокирован."])},
    "Virus infected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заражено вирусом"])},
    "Botnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botnet"])},
    "Malware/Phishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вредоносное ПО / Фишинг"])},
    "Spyware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spyware"])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина блокировки"])},
    "ssl_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка подключения к источнику. Сертификат не является доверенным."])}
  },
  "stop-po": {
    "content_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр содержимого"])},
    "threat_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Защита от угроз"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировано!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш запрос был заблокирован в целях обеспечения вашей безопасности.\nВведите пароль для разблокировки."])},
    "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина блокировки"])},
    "unblock_card_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблокировать URL"])},
    "Unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблокировать"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "The password field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле пароля обязательно для заполнения"])}
  },
  "ask-user-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировано!"])},
    "block message ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веб-сайт, к которому вы пытаетесь получить доступ, имеет недействительный сертификат и заблокирован."])},
    "ssl_unblock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы по-прежнему доверяете сертификату и хотите получить доступ к веб-сайту, нажмите кнопку «Разблокировать»."])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблокировать"])}
  }
}