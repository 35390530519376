import AuthLayout from 'src/pages/AuthLayout.vue';

const StopPage = () => import('src/pages/GeneralViews/StopPage.vue');
const StopPO = () => import('src/pages/GeneralViews/StopPO.vue');
const AskUserPage = () => import ('src/pages/GeneralViews/AskUserPage.vue');

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    { path: '/stoppo',
      name: 'stoppo',
      component: StopPO,
      props: (route) => ({ url: route.query.URL, rule: route.query.RULE, device: route.query.CLIENTID })
    },
    { path: '/stop',
      name: 'stop',
      component: StopPage,
      props: { service: 'cf' }
      //props: (route) => ({ src: route.query.src, service: route.query.service, rule: route.query.RULE, reason: route.query.reason })
    },
    { path: '/stopav',
      name: 'stopav',
      component: StopPage,
      props: { service: 'tp', reason: 'av' }
      //props: (route) => ({ src: route.query.src, service: route.query.service, rule: route.query.RULE, reason: route.query.reason })
    },
    { path: '/stopab',
      name: 'stopab',
      component: StopPage,
      props: { service: 'tp', reason: 'bt' }
      //props: (route) => ({ src: route.query.src, service: route.query.service, rule: route.query.RULE, reason: route.query.reason })
    },
    { path: '/stopap',
      name: 'stopap',
      component: StopPage,
      props: { service: 'tp', reason: 'ap' }
      //props: (route) => ({ src: route.query.src, service: route.query.service, rule: route.query.RULE, reason: route.query.reason })
    },
    { path: '/stopinvalidcertificate',
      name: 'stopinvalidcertificate',
      component: StopPage,
      props: { service: 'ssl', reason: 'ssl_invalid'}
    },
    { path: '/askinvalidcertificate',
      name: 'askinvalidcertificate',
      component: AskUserPage,
      props: (route) => ({
        checkSslValue: route.query.checkssl,
        service: 'ssl',
        reason: 'ssl_unblock_message'
      })
    }
  ]
};

const routes = [
  authPages,
  //{ path: '*', redirect: '/' }
];

export default routes;
