<template>
  <div id="app" :class="{'text-dir-rtl': isTextRtl}">
    <!--<b-alert class="floating" :variant="type" dismissible fade
      :show="dismissCountDown" @dismissed="dismissCountDown=0" 
      @dismiss-count-down="countDownChanged">
      {{ t(message) }}
      <b-progress :variant="type" :max="dismissSecs" striped :animated="true"
            :value="dismissCountDown" height="0.5rem"/>
    </b-alert>-->

    <loading :loading="loading" />

    <router-view class="absolute"></router-view>
  </div>
</template>

<script>
import Loading from 'src/components/Loading'
import {mapGetters} from "vuex";

export default {
  components: {
    Loading,
    },
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      type: 'danger',
      message: ''
    }
  },
  created() {
    /* Remove comment to use navigator user language
    let language = navigator.language || navigator.userLanguage
    if ( language.indexOf('-') > 0)
      language = language.split('-')[0] 
    this.setLanguage(language) */
    this.setLanguage('en');
  },
  mounted() {
    this.$nextTick( function() {
      window.addEventListener('resize', this.setLayout);
    })
    this.$store.state.mobile = this.isMobile();

    this.getThings();
    /*
    let vm = this;
    let intBackend = setInterval(() => {
      if (vm.$store.state.backend_ok){
        vm.getThings();
        clearInterval(intBackend);
      }
    }, 100);
    */

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setLayout)
  },
  computed: {
    ...mapGetters (['isTextRtl']),
    error: function() {
      return this.$store.getters.error;
    },  
    success: function() {
      return this.$store.getters.success;
    },
    loading: function() {
      return this.$store.getters.loading
    },
  },
  watch: {
    error (newError, oldError){
      if (newError != null){
        this.type = "danger"
        this.message = newError;
        this.dismissCountDown = this.dismissSecs;
      }
    },
    success (newSuccess, oldSuccess){
      if (newSuccess != null){
        this.type = "success";
        this.message = newSuccess;
        if (newSuccess)
          this.dismissCountDown = this.dismissSecs;
      }
    }
  },
  methods: {
    async getThings(){
    },
    setLanguage(language){
      this.$store.dispatch('setLanguage', language)
    },
    setLayout(event){
      //console.log(`route: ${this.$route.path}`)
      this.$store.state.mobile = this.isMobile();
    },
    countDownChanged(dismissCountDown){
      this.dismissCountDown = dismissCountDown;
      if (this.dismissCountDown == 0){
        if (this.type == 'success')
          this.$store.commit('removeSuccess');
        else if (this.type == 'danger')
          this.$store.commit('removeError');
      }
    }
  }


};
</script>
<style>
  .absolute{
    width: 100%;
  }
  .text-dir-rtl p, .text-dir-rtl h1 {
    direction: rtl !important;
  }
</style>
